<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import isEmail from 'validator/es/lib/isEmail'
import isMultibyte from 'validator/es/lib/isMultibyte'
import { string } from 'yup'
import { useForm, configure } from 'vee-validate'
import { SpButton, SpInput, SpLabel } from '@tithely/sproutkit-vue'
import { useMe, useMeUpdate } from '@/queries/me'
import BaseModal from '@/components/base/BaseModal.vue'
import BaseModalHeader from '@/components/base/BaseModalHeader.vue'
import FormError from '@/components/FormError.vue'
import UserMfaSettings from '@/components/modals/UserMfaSettings.vue'
import UserEmailVerification from '@/components/modals/UserEmailVerification.vue'
import useApi from '@/composables/api'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import { useToast } from 'vue-toastification'
import { omit } from 'lodash'

const toast = useToast()

const modal = ref<InstanceType<typeof BaseModal>>()

const { data, hasFeature, status, refetch, isRefetching, isLinked } = useMe()
const isTithelyPrimary = computed(() => {
    return isLinked.value && data.value?.isPrimary
})
const { mutate: updateMe, status: updateStatus } = useMeUpdate()
const userMfaSettingsModal = ref<InstanceType<typeof UserMfaSettings>>()
const { api } = useApi()
const userEmailVerificationModal = ref<InstanceType<typeof UserEmailVerification>>()
const isLoadingMfaDisable = ref(false)
const isLoadingEmailValidation = ref(false)

const { defineField, errors, handleSubmit, setValues } = useForm({
    initialValues: {
        username: data.value?.username ?? ``,
        email: data.value?.email ?? ``,
        send_from: data.value?.sendFrom ?? data.value?.email ?? ``
    },
    validationSchema: {
        username: string()
            .required(`Username is required`)
            .min(3, `Username is too short`)
            .test(`is-valid`, `Username contains invalid characters`, value => !isMultibyte(value)),
        email: string()
            .required(`Email address is required`)
            .test(`is-valid`, `Email address is invalid`, value => isEmail(value)),
        send_from: string()
            .required(`Send From is required`)
            .test(`is-valid`, `Send From must be valid email`, value => isEmail(value))
    }
})

configure({ validateOnModelUpdate: false })

const [username, usernameAttrs] = defineField(`username`)
const [email, emailAttrs] = defineField(`email`)
const [sendFrom, sendFromAttrs] = defineField(`send_from`)

const isLoading = computed(() => status.value === `pending` || updateStatus.value === `pending`)

const close = () => {
    modal.value?.close()
}

const submit = handleSubmit(values => {
    updateMe(isTithelyPrimary.value ? omit(values, 'email') : values, {
        onSuccess: () => {
            close()
            toast.success(`User was updated successfully!`)
        },
        onError: error => {
            if (error.message === 'errors.emails.change_tithely_primary') {
                toast.error(`Cannot update primary user when connected to Tithe.ly`)
            } else {
                toast.error(`Failed to update user`)
            }
        }
    })
})

watch<string>(status, newStatus => {
    if (newStatus === 'success') {
        setValues({
            username: data.value?.username,
            email: data.value?.email
        })
    }
})

const openUserMfaSettingsModal = () => {
    userMfaSettingsModal.value?.open()
}

const disableMFA = () => {
    isLoadingMfaDisable.value = true
    if (data.value?.mfaStatus === `totp`) {
        api.authorizationLoggedInUser.disableTotpmfa().then(() => {
            refetch()
            isLoadingMfaDisable.value = false
        })
    }

    if (data.value?.mfaStatus === `email`) {
        api.authorizationLoggedInUser.disableEmailMfa().then(() => {
            refetch()
            isLoadingMfaDisable.value = false
        })
    }
}

const mfaStatusType = computed(() => {
    switch (data.value?.mfaStatus) {
        case `totp`:
            return `Authenticator`
        case `email`:
            return `Email`
        default:
            return `Email`
    }
})

const emailVerficationFlow = () => {
    isLoadingEmailValidation.value = true
    api.authorizationLoggedInUser.requestEmailVerification().then(() => {
        isLoadingEmailValidation.value = false
        userEmailVerificationModal.value?.open()
    })
}
</script>

<template>
    <BaseModal
        ref="modal"
        size="md"
        class="flex flex-col gap-4 !p-4">
        <BaseModalHeader @close="close">
            <h2 class="text-2xl font-medium">User Settings</h2>
        </BaseModalHeader>

        <form
            class="!m-0 grid grid-cols-1 gap-4 sm:grid-cols-2"
            @keydown.enter.prevent="submit">
            <div class="col-span-2 !ml-0 flex flex-col">
                <SpLabel required>Username</SpLabel>
                <SpInput
                    v-model="username"
                    v-bind="usernameAttrs"
                    :disabled="isLoading"
                    class="w-full" />

                <FormError
                    class="mt-1"
                    :error="errors.username" />
            </div>

            <div class="col-span-2 !ml-0 flex flex-col sm:col-span-1">
                <SpLabel>First Name</SpLabel>
                <SpInput
                    :model-value="data?.firstName"
                    disabled
                    class="w-full" />
            </div>

            <div class="col-span-2 !ml-0 flex flex-col sm:col-span-1">
                <SpLabel>Last Name</SpLabel>
                <SpInput
                    :model-value="data?.lastName"
                    disabled
                    class="w-full" />
            </div>

            <div class="col-span-2 !ml-0 flex flex-col">
                <SpLabel required>Email</SpLabel>
                <SpInput
                    v-model="email"
                    v-bind="emailAttrs"
                    :disabled="isLoading || (isTithelyPrimary && hasFeature('duplicate-user-prevention'))"
                    class="w-full" />

                <template v-if="hasFeature('vue-mfa')">
                    <span
                        v-if="data?.emailVerified"
                        class="text-xs/5 font-medium text-green-500"
                        >{{ $t(`user.emailVerify.verifiedStatus`) }}</span
                    >
                    <div v-else>
                        <span
                            v-if="!isLoadingEmailValidation"
                            class="text-xs/5 font-medium text-danger-500"
                            @click="emailVerficationFlow">
                            {{ $t(`user.emailVerify.notVerified`) }}
                            <a class="text-primary-dark">{{ $t(`user.emailVerify.validate`) }}</a>
                        </span>
                        <LoadingSpinner
                            v-else
                            class="mt-4 h-4" />
                    </div>
                </template>

                <FormError
                    class="mt-1"
                    :error="errors.email" />

                <SpLabel required>Send From</SpLabel>

                <SpInput
                    v-model="sendFrom"
                    v-bind="sendFromAttrs"
                    :disabled="isLoading"
                    class="w-full" />

                <FormError
                    class="mt-1"
                    :error="errors.send_from" />
            </div>

            <SpButton
                v-if="hasFeature(`vue-mfa`) && !Boolean(data?.mfaStatus)"
                @click="openUserMfaSettingsModal">
                Enable MFA Login
            </SpButton>

            <div class="col-span-2 !ml-0 flex">
                <div>
                    <SpButton
                        v-if="hasFeature(`vue-mfa`) && Boolean(data?.mfaStatus)"
                        :loading="isLoadingMfaDisable || isRefetching"
                        intent="danger"
                        @click="disableMFA">
                        {{ $t(`user.mfa.modal.disableMfa`, { type: mfaStatusType }) }}
                    </SpButton>
                </div>
            </div>
        </form>

        <footer class="flex items-center justify-end gap-4 rounded-b-lg">
            <SpButton
                intent="secondary"
                variant="subtle"
                :disabled="isLoading"
                @click="close">
                Cancel
            </SpButton>

            <SpButton
                :loading="isLoading"
                @click="submit">
                {{ isLoading ? `Updating` : `Update` }}
            </SpButton>
        </footer>

        <UserEmailVerification
            ref="userEmailVerificationModal"
            @close="close" />

        <UserMfaSettings
            ref="userMfaSettingsModal"
            @close="close" />
    </BaseModal>
</template>
